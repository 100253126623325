import React, { ChangeEvent, useContext, useState } from "react";

import { LngContext } from "../../context/lng-context";
import {
  ClockIcon,
  ContactContainer,
  ContactDescription,
  ContactFormContainer,
  ContactHeader,
  ContactInfoContainer,
  ContactInfoPart,
  ContactLogo,
  EmailIcon,
  InfoContainer,
  InfoTextContainer,
  InputesContainer,
  InputName,
  InputPhone,
  PhoneIcon,
  QuestionArea,
  SendBtn,
} from "./contact.styles";
import { sendTGMessage } from "../../services/api";

const Contact = () => {
  const texts = useContext(LngContext);
  const [fields, setFields] = useState({ name: "", phone: "", question: "" });

  const onFieldsChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields({ ...fields, [name]: value });
  };

  const onSend = async () => {
    // await fetch("/send-to-telegram", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ ...fields }),
    // });
    const message = `📞 *Phone*: ${fields.phone}\n👤 *Name*: ${fields.name}\n❓ *Question*: ${fields.question}`;

    await sendTGMessage(message);

    setFields({ name: "", phone: "", question: "" });
  };

  return (
    <ContactContainer>
      <ContactFormContainer>
        <ContactHeader>{texts.constacts.title}</ContactHeader>
        <ContactDescription>{texts.constacts.text}</ContactDescription>
        <InputesContainer>
          <InputName
            placeholder={texts.constacts.form.name}
            name="name"
            value={fields.name}
            onChange={onFieldsChange}
          />
          <InputPhone
            placeholder={texts.constacts.form.phone}
            name="phone"
            value={fields.phone}
            onChange={onFieldsChange}
          />
        </InputesContainer>
        <QuestionArea
          placeholder={texts.constacts.form.content}
          name="question"
          value={fields.question}
          onChange={onFieldsChange}
        />
        <SendBtn onClick={onSend}>{texts.constacts.form.send}</SendBtn>
      </ContactFormContainer>
      <ContactInfoPart>
        <ContactInfoContainer>
          <InfoContainer>
            <PhoneIcon />
            <InfoTextContainer>
              <span>{texts.constacts.contact.phone}</span>
              <span>
                +998 55 702 70 02 <br />
                +998 66 239 70 02
              </span>
            </InfoTextContainer>
          </InfoContainer>
          <InfoContainer>
            <EmailIcon />
            <InfoTextContainer>
              <span>E-mail:</span>
              <span>amirtea-samarqand@mail.ru</span>
            </InfoTextContainer>
          </InfoContainer>
          <InfoContainer>
            <ClockIcon />
            <InfoTextContainer>
              <span>{texts.constacts.contact.work}</span>
              <span>{texts.constacts.contact.time}</span>
            </InfoTextContainer>
          </InfoContainer>
        </ContactInfoContainer>
        <ContactLogo />
      </ContactInfoPart>
    </ContactContainer>
  );
};

export default Contact;
