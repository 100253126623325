import styled from "styled-components";

import { ReactComponent as FullLogoSvg } from "../../assets/svg/full-logo.svg";
import { ReactComponent as LogoGreenSvg } from "../../assets/svg/logo-green.svg";
import { ReactComponent as ArrowSvg } from "../../assets/svg/arrow.svg";
import { ReactComponent as TgSvg } from "../../assets/svg/tg.svg";
import { ReactComponent as InstaSvg } from "../../assets/svg/insta.svg";
import { ReactComponent as FBSvg } from "../../assets/svg/fb.svg";
import { ReactComponent as MenuSvg } from "../../assets/svg/menu.svg";

export const TopMenuContainer = styled.div`
  z-index: 1000;
  width: 91%;
  height: 93px;
  position: fixed;
  border-radius: 0px 0px 30px 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(12px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  right: 50%;
  transform: translate(50%, 0);
  box-sizing: border-box;

  @media screen and (max-width: 1080px) {
    height: 60px;
    border-radius: 0px 0px 15px 15px;
  }
`;

export const FullLogo = styled(FullLogoSvg)`
  position: absolute;
  left: 2.5vw;
  top: -5px;
  max-width: 204px;
  max-height: 208px;
  width: 10.6vw;
  height: 10.8vw;
  min-width: 109px;
  min-height: 112px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    left: 0;
  }
`;

export const PhoneContainer = styled.div`
  padding: 18px 34px;
  box-sizing: border-box;
  border-radius: 22px;
  background: linear-gradient(90deg, #3a7a30 0%, #2fae1b 100%);
  position: absolute;
  right: 16px;
  font-size: 19.06px;
  font-weight: 500;
  color: white;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);

  @media screen and (max-width: 1600px) {
    display: none;
  }
`;

export const LanguageChose = styled.div`
  position: absolute;
  right: 290px;
  font-size: 18.75px;
  font-weight: 400;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;

  @media screen and (max-width: 1600px) {
    right: 28px;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const ArrowDrop = styled(ArrowSvg)`
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const TGIcon = styled(TgSvg)`
  cursor: pointer;
  width: 23px;
  height: 23px;
  padding: 10px;
  border-radius: 9px;
  background: rgba(48, 175, 27, 1);

  @media screen and (max-width: 1080px) {
    width: 31px;
    height: 31px;
    padding: 13.5px;
  }
`;

export const FBIcon = styled(FBSvg)`
  cursor: pointer;
  width: 23px;
  height: 23px;
  padding: 10px;
  border-radius: 9px;
  background: rgba(48, 175, 27, 1);

  @media screen and (max-width: 1080px) {
    width: 31px;
    height: 31px;
    padding: 13.5px;
  }
`;

export const InstaIcon = styled(InstaSvg)`
  cursor: pointer;
  width: 23px;
  height: 23px;
  padding: 10px;
  border-radius: 9px;
  background: rgba(48, 175, 27, 1);

  @media screen and (max-width: 1080px) {
    width: 31px;
    height: 31px;
    padding: 13.5px;
  }
`;

export const MenuIcon = styled(MenuSvg)`
  width: 24px;
  height: 24px;
  padding: 10px;
  border-radius: 9px;
  background: rgba(48, 175, 27, 1);
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  display: none;
  position: absolute;

  @media screen and (max-width: 1080px) {
    display: block;
  }
`;

export const LinksContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 16.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.45vw;

  @media screen and (max-width: 1200px) {
    left: 12vw;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const NavContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 31.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.1vw;
  font-size: 18.91px;
  font-weight: 400;
  line-height: 22.88px;
  text-align: center;

  @media screen and (max-width: 1200px) {
    gap: 20px;
    left: 28vw;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }

  span {
    cursor: pointer;
  }
`;

export const MobileMenuContainer = styled.div`
  padding: 60px 62px 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  z-index: 1001;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  background: black;

  @media screen and (min-width: 1080px) {
    display: none;
  }
`;

export const LogoGreen = styled(LogoGreenSvg)`
  width: 275px;
  height: 70.28px;
`;

export const MobileIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const MobileNavContainer = styled.div`
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  color: white;
`;

export const MobileLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const MobileLngChoseContainer = styled.div`
  width: 275px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobilePhoneContainer = styled.div`
  width: 275px;
  height: 44px;
  box-sizing: border-box;
  padding: 12.5px 34px;
  border-radius: 9px 0px 0px 0px;
  font-size: 19px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  border-radius: 9px;
  color: white;
  background: linear-gradient(90deg, #3a7a30 0%, #2fae1b 100%);
`;

interface IMobileLngChoseProps {
  $isSelected: boolean;
}

export const MobileLngChose = styled(
  MobilePhoneContainer
)<IMobileLngChoseProps>`
  width: 128px;
  background: ${({ $isSelected }) =>
    $isSelected ? "linear-gradient(90deg, #3a7a30 0%, #2fae1b 100%)" : "white"};
  color: ${({ $isSelected }) =>
    $isSelected ? "white" : "rgba(47, 172, 27, 1)"};
`;
