import React, { FC, MutableRefObject } from "react";

import Header from "../components/header/header.component";
import About from "../components/about/about.component";
import Promotion from "../components/promotion/promotion.component";
import Contact from "../components/contact/contact.component";
import Map from "../components/map/map.component";
import ClassBanner from "../components/class-banner/class-banner.component";

const MainLoyout: FC<{
  aboutRef: MutableRefObject<HTMLDivElement | null>;
  catalogRef: MutableRefObject<HTMLDivElement | null>;
  contactsRef: MutableRefObject<HTMLDivElement | null>;
  navigation: {
    navToAbout: () => void;
    navToCatalog: () => void;
    navToContacts: () => void;
    navToCatalogPage: () => void;
  };
}> = ({ aboutRef, catalogRef, contactsRef, navigation }) => {
  return (
    <>
      <Header navToContact={navigation.navToContacts} />
      <ClassBanner onClick={navigation.navToCatalogPage} />
      <span ref={aboutRef} />
      <About />
      <span ref={catalogRef} />
      <Promotion />
      <span ref={contactsRef} />
      <Contact />
      <Map navToContact={navigation.navToContacts} />
    </>
  );
};

export default MainLoyout;
