import React, { FC } from "react";

import img from "../../assets/img/tea-on-tabel.png";
import {
  ClassBannerContainer,
  ClassBannerImgLeft,
  ClassBannerImgRight,
} from "./class-banner.styles";

const ClassBanner: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <ClassBannerContainer onClick={onClick}>
      <ClassBannerImgLeft src={img} />
      <ClassBannerImgRight src={img} />
      <div>
        Здесь вы можете ознакомиться
        <br />
        <span>со всем разнообразием</span>
        <br />
        нашего чая
      </div>
      <button>Классификация чая</button>
    </ClassBannerContainer>
  );
};

export default ClassBanner;
