import styled from "styled-components";

export const ClassBannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 370px;
  margin-top: 60px;
  padding-top: 46px;
  padding-bottom: 33px;
  box-sizing: border-box;
  gap: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  overflow: hidden;
  cursor: pointer;

  div {
    font-size: 44px;
    font-weight: 600;
    line-height: 46.23px;
    text-align: center;
    z-index: 100;

    span {
      color: #3c67ff;
    }
  }

  button {
    box-sizing: border-box;
    width: 413px;
    height: 76px;
    padding: 18px 34px;
    border-radius: 12px;
    background: linear-gradient(90deg, #3c67ff 0%, #1636a8 100%);
    white-space: nowrap;
    font-family: Inter;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    border: none;
    outline: none;
    color: white;
    z-index: 100;
  }

  @media screen and (max-width: 750px) {
    height: auto;
    gap: 40px;
    justify-content: center;
    padding: 0;

    div {
      font-size: 28px;
      line-height: 33px;
    }

    button {
      width: 357px;
      height: 64px;
      font-size: 20px;
    }
  }
`;

export const ClassBannerImgLeft = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 750px) {
    transform: rotate(90deg);
    position: relative;
    height: 400px;
    width: 100vw;
    object-fit: cover;
    left: auto;
    top: auto;
  }
`;

export const ClassBannerImgRight = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
