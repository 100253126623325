import React, { useContext, useEffect, useState } from "react";

import { LngContext } from "../context/lng-context";
import testImg from "../assets/img/tea.png";

import { CatalogHeader } from "../components/header/header.component";
import {
  CatalogContainer,
  CatalogItem,
  CatalogItemImg,
  CatalogItemText,
  CatalogItemTitle,
  CatalogPageContainer,
  CatalogTitle,
  Category,
  CategoryContainer,
  TeaSpecificsContainer,
  WhiteDot,
} from "../components/catalog/catalog.styles";
import { getCatalogItems } from "../services/api";

const CatalogPage = () => {
  const allTexts = useContext(LngContext);
  const texts = allTexts.catalogPage;

  type TeaType = keyof typeof texts.teaSpecifics;
  type CatalogItemType = {
    id: number;
    name: string;
    made: string;
    category: TeaType;
    img_url: string;
  };

  const [catalogItems, setCatalogItems] = useState<CatalogItemType[]>([]);
  const [currentTea, setCurrentTea] = useState<TeaType>(
    Object.keys(texts.types)[0] as TeaType
  );

  useEffect(() => {
    getCatalogItems().then((data) => setCatalogItems(data));
  }, []);

  return (
    <div style={{ background: texts.teaSpecifics[currentTea].color }}>
      <CatalogHeader text={allTexts.topMenu.catalogPage} />
      <CatalogPageContainer>
        <CategoryContainer>
          {Object.keys(texts.types).map((type) => (
            <Category
              key={type}
              $active={currentTea === type}
              onClick={() => setCurrentTea(type as TeaType)}>
              {texts.types[type as TeaType]}
            </Category>
          ))}
        </CategoryContainer>
        <CatalogTitle>{texts.types[currentTea]}</CatalogTitle>
        <span>{texts.teaSpecifics[currentTea].title}</span>
        <TeaSpecificsContainer>
          {texts.teaSpecifics[currentTea].specifics.map((specific) => (
            <div key={specific}>
              <WhiteDot /> <span>{specific}</span>
            </div>
          ))}
        </TeaSpecificsContainer>
        <span>{texts.teaSpecifics[currentTea].text}</span>
        <CatalogContainer>
          {catalogItems
            .filter((item) => item.category === currentTea)
            .map((item) => (
              <CatalogItem key={item.id}>
                <CatalogItemImg src={item.img_url} />
                <CatalogItemTitle>{item.name}</CatalogItemTitle>
                <CatalogItemText>
                  {texts.made}: {item.made}
                </CatalogItemText>
                <CatalogItemText>
                  {texts.category}: {item.category}
                </CatalogItemText>
              </CatalogItem>
            ))}
        </CatalogContainer>
      </CatalogPageContainer>
    </div>
  );
};

export default CatalogPage;
