import styled from "styled-components";

import { ReactComponent as linkSvg } from "../../assets/svg/link.svg";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 961px;
  position: relative;

  @media screen and (max-width: 750px) {
    height: 645px;
  }
`;

export const CatalogHeaderContainer = styled.div`
  width: 100%;
  height: 405px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  @media screen and (max-width: 750px) {
    height: 262px;
  }
`;

export const CatalogHeaderTitle = styled.span`
  font-family: Inter;
  font-size: 54px;
  font-weight: 700;
  line-height: 65px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;

  @media screen and (max-width: 750px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const HeaderImage = styled.img`
  z-index: -100;
  width: 100%;
  height: 100%;
  filter: brightness(0.7);
  position: absolute;
  background-size: cover;
`;

export const LeafContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: -1;
`;

export const Leaf = styled.img`
  width: 34.8vw;
  height: 37.1vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const LeafMirror = styled.img`
  width: 34.7vw;
  height: 38.5vw;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(-1);
  z-index: -1;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const HeaderContentContainer = styled.div`
  margin: 0 17vw;
  margin-top: 14.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  @media screen and (max-width: 750px) {
    margin: 0 13px;
    margin-top: 187px;
  }
`;

export const HeaderText = styled.span`
  color: white;
  font-size: 68px;
  font-weight: 500;
  line-height: 65px;
  text-align: center;

  @media screen and (max-width: 750px) {
    font-size: 30px;
    font-weight: 700;
    line-height: 28.26px;
  }
`;

export const HeaderTextGreen = styled(HeaderText)`
  color: rgba(34, 244, 0, 1);
  font-weight: 700;
`;

export const HeaderContent = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  text-align: center;
  color: var(--color-grey-85, rgba(217, 217, 217, 1));

  @media screen and (max-width: 750px) {
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const ContactButton = styled.div`
  height: 56px;
  padding: 18px 22px;
  border-radius: 12px;
  box-sizing: border-box;
  background: linear-gradient(270deg, #3a7a30 0%, #2fae1b 100%);
  color: white;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  gap: 16px;
  white-space: nowrap;

  @media screen and (max-width: 750px) {
    height: 68px;
    padding: 18px 34px;
    border-radius: 12px;
  }
`;

export const LinkIcon = styled(linkSvg)`
  width: 14px;
  height: 14px;
`;
