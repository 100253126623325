const API_URL = "https://api.amirtea.uz";

export async function getCatalogItems() {
  const response = await fetch(`${API_URL}/api/tea/`);
  return response.json();
}

export async function sendTGMessage(data: string) {
  const response = await fetch(`${API_URL}/api/tg/send_message/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
