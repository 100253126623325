import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import textsJson from "../assets/texts.json";
import { LngContext } from "../context/lng-context";

import TopMenu from "../components/top-menu/top-menu.component";
import Fotter from "../components/footer/footer.component";
import MainLoyout from "./main-loyout";
import CatalogPage from "./catalog-loyout";
const AdminLoyout = lazy(() => import("./adiminloyout"));

function AppLoyout() {
  const [texts, setTexts] = useState(textsJson.ru);
  const [searchParams] = useSearchParams();
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const catalogRef = useRef<HTMLDivElement | null>(null);
  const contactsRef = useRef<HTMLDivElement | null>(null);

  const nav = useNavigate();

  const handleNavigation = (path: string) => {
    nav(`${path}?${searchParams.toString()}`);
  };

  useEffect(() => {
    switch (searchParams.get("lng")) {
      case "ru":
        setTexts(textsJson.ru);
        break;
      case "uz":
        setTexts(textsJson.uz);
        break;
      default:
        setTexts(textsJson.ru);
        break;
    }
  }, [searchParams]);

  const navigation = {
    navToAbout: () => {
      handleNavigation("/");
      setTimeout(() => {
        aboutRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 50);
    },
    navToCatalog: () => {
      handleNavigation("/");
      setTimeout(() => {
        catalogRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 50);
    },
    navToContacts: () => {
      handleNavigation("/");
      setTimeout(() => {
        contactsRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 50);
    },
    navToCatalogPage: () => {
      handleNavigation("/catalog");
    },
    navToMainPage: () => {
      handleNavigation("/");
    },
  };

  return (
    <>
      <LngContext.Provider value={texts}>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <TopMenu navigation={navigation} />
                <MainLoyout
                  aboutRef={aboutRef}
                  catalogRef={catalogRef}
                  contactsRef={contactsRef}
                  navigation={navigation}
                />
                <Fotter navigation={navigation} />
              </Suspense>
            }
          />
          <Route
            path="/catalog"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <TopMenu navigation={navigation} />
                <CatalogPage />
                <Fotter navigation={navigation} />
              </Suspense>
            }
          />
          <Route
            path="/admin"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AdminLoyout />
              </Suspense>
            }
          />
        </Routes>
      </LngContext.Provider>
    </>
  );
}

export default AppLoyout;
